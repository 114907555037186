import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './RemovedIngredients.styl';

const cx = classNames.bind(styles);

export default function RemovedIngredients(props) {

  const makeIngredients = () => {
    return {
      __html: props.list.map(ingredient => `-&nbsp;${ingredient.name}`).join(', ')
    };
  };

  const ingredients = makeIngredients();
  return (
    <div
      data-test-id='removed-ingredients'
      className={cx('RemovedIngredients', props.className)}
      dangerouslySetInnerHTML={ingredients}
    />
  );
}

RemovedIngredients.defaultProps = {
  className: '',
  list: []
};

RemovedIngredients.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array
};
