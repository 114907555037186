import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import PrimaryView from './partials/PrimaryView';
import SecondaryView from './partials/SecondaryView';

// Styles
import styles from './NavBar.styl';

const cx = classNames.bind(styles);

export default function NavBar(props) {
  const { className, view, children, title, onBack } = props;

  if (view === 'secondary') {
    return (
      <SecondaryView className={cx('NavBar', className)} title={title} onBack={onBack}>
        {children}
      </SecondaryView>
    );
  }

  return (
    <PrimaryView className={cx('NavBar', className)} title={title} onBack={onBack}>
      {children}
    </PrimaryView>
  );
}

NavBar.defaultProps = {
  className: '',
  view: 'primary',
  title: '',
  children: null,
};

NavBar.propTypes = {
  className: PropTypes.string,
  view: PropTypes.oneOf(['primary', 'secondary']),
  onBack: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
};
