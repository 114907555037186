import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './Scene.styl';

const cx = classNames.bind(styles);

export default function Scene(props) {
  const { renderNavbar, renderFooter, className, children } = props;

  return (
    <div className={cx('Scene', className)}>
      <div className={cx('Scene__navbar')}>{renderNavbar()}</div>
      <div className={cx('Scene__body')} id="SceneScroll">
        {children}
      </div>
      {renderFooter && <div className={cx('Scene__footer')}>{renderFooter()}</div>}
    </div>
  );
}

Scene.defaultProps = {
  className: '',
  renderFooter: null,
};

Scene.propTypes = {
  children: PropTypes.node.isRequired,
  renderNavbar: PropTypes.func.isRequired,
  className: PropTypes.string,
  renderFooter: PropTypes.func,
};
