import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../../../Heading';

// Icons
import BackIcon from '../../../../icons/back.svg';

// Styles
import styles from './BackButton.styl';

const cx = classNames.bind(styles);

export default function BackButton(props) {
  const { className, children, onClick } = props;

  return (
    <div className={cx('BackButton', className)} onClick={onClick}>
      <BackIcon width={18} height={18} />
      {children && (
        <Heading className={cx('BackButton__label')} level={4} tagName="div">
          {children}
        </Heading>
      )}
    </div>
  );
}

BackButton.defaultProps = {
  className: '',
};

BackButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};
