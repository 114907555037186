import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Style
import styles from './AddedIngredients.styl';

const cx = classNames.bind(styles);

AddedIngredients.defaultProps = {
  className: '',
  list: [],
};

AddedIngredients.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  intl: PropTypes.object,
};

function AddedIngredients(props) {
  const { className, list, intl } = props;

  const isItSauce = type => type === intl.formatMessage({ id: 'sauce' });

  return (
    <div
      data-test-id='added_ingredients'
      className={cx('AddedIngredients', className)}
      dangerouslySetInnerHTML={{
        __html: list
          .sort((a, b) => isItSauce(b.type) - isItSauce(a.type))
          .map(({ name, count }) => `+&nbsp;${name}${count > 1 ? ` x${count}` : ''}`)
          .join(', '),
      }}
    />
  );
}

export default injectIntl(AddedIngredients);
