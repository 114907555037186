import React from 'react';
import PropTypes from 'prop-types';

// Components
import BackButton from '../BackButton';

// Styles
import styles from './SecondaryView.styl';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function SecondaryView(props) {
  const { className, children, onBack, title } = props;

  return (
    <div className={className}>
      {onBack && <BackButton onClick={onBack}>{title}</BackButton>}
      {children &&
        <div className={cx('SecondaryView__item')}>
          {children}
        </div>}
    </div>
  );
}

SecondaryView.defaultProps = {
  className: '',
  title: '',
  children: null
};

SecondaryView.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  children: PropTypes.any
};
