import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../../../Heading';
import BackButton from '../BackButton';

// Styles
import styles from './PrimaryView.styl';

const cx = classNames.bind(styles);

export default function PrimaryView(props) {
  const { className, children, title, onBack } = props;

  return (
    <div className={cx('PrimaryView', className)}>
      <BackButton onClick={onBack} />
      <div className={cx('PrimaryView__content')}>
        {title ? (
          <Heading level={4} medium>
            {title}
          </Heading>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

PrimaryView.defaultProps = {
  className: '',
  title: '',
};

PrimaryView.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
};
